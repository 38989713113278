import { createContext, useContext } from 'react';
import { InitProtoConfig, InitVault, InitVaultProps } from '../component';
import { InitOracleConfig, InitOracleConfigProps } from '../component/actions/InitOracleConfig';
import {
  SetVaultOracleConfig,
  SetVaultOracleConfigProps
} from '../component/actions/SetVaultOracleConfig';

export enum Action {
  initVaultProtoConfig,
  initVault,
  initOracleConfig,
  setVaultOracleConfig,
  menu
}

export type Actions = Record<
  number,
  {
    title: string;
    component: (props?: ActionProps) => JSX.Element;
  }
>;

export type ActionProps = InitVaultProps | InitOracleConfigProps | SetVaultOracleConfigProps;

export const actions: Actions = {
  [Action.initVaultProtoConfig]: {
    title: 'Deploy a New Vault Proto Config',
    component: () => {
      return <InitProtoConfig />;
    }
  },
  [Action.initVault]: {
    title: 'Deploy a New Vault',
    component: (props?: ActionProps) => {
      return <InitVault {...(props as InitVaultProps)} />;
    }
  },
  [Action.initOracleConfig]: {
    title: 'Deploy a New Oracle Config',
    component: (props?: ActionProps) => {
      return <InitOracleConfig {...(props as InitOracleConfigProps)} />;
    }
  },
  [Action.setVaultOracleConfig]: {
    title: 'Set Vault Oracle Config',
    component: (props?: ActionProps) => {
      return <SetVaultOracleConfig {...(props as SetVaultOracleConfigProps)} />;
    }
  }
};

export interface ActionContextProps {
  setActionContext: (actionContext: { action?: Action; props?: ActionProps }) => void;
}

export const ActionContext = createContext<ActionContextProps>({
  setActionContext: () => {
    console.log('undefined action drawer context');
  }
});

export function useActionContext(): ActionContextProps {
  return useContext(ActionContext);
}
