import { EditIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { Address } from '@project-serum/anchor';
import { Action, useActionContext } from '../contexts/ActionContext';

interface SetVaultOracleConfigButtonProps {
  vault: Address;
}

export function SetVaultOracleConfigButton({
  vault,
  disabled,
  ...buttonProps
}: SetVaultOracleConfigButtonProps & ButtonProps) {
  const actionContext = useActionContext();

  return (
    <Tooltip label={`Set Oracle Config`}>
      <Button
        width={'100%'}
        disabled={disabled}
        {...buttonProps}
        aria-label="Set Vault Oracle Config"
        onClick={() =>
          actionContext.setActionContext({
            action: Action.setVaultOracleConfig,
            props: { vault }
          })
        }
        icon={<EditIcon />}
      >
        {'Set'}
      </Button>
    </Tooltip>
  );
}
