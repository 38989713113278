import { Text, Box, Skeleton, Image, HStack, VStack } from '@chakra-ui/react';
import styled from 'styled-components';
import { WarningIcon } from '@chakra-ui/icons';

export interface TokenPairProps {
  tokenAMint: string;
  tokenASymbol?: string;
  tokenAIconUrl?: string;

  tokenBMint: string;
  tokenBSymbol?: string;
  tokenBIconUrl?: string;
}

// function CopyButton({ onClick }: { onClick: () => void }) {
//   return (
//     <IconButton
//       colorScheme={'blue'}
//       aria-label="Copy Address"
//       icon={<CopyIcon />}
//       onClick={onClick}
//     />
//   );
// }

function tokenInfo(mint: string, isA: boolean, symbol?: string, iconUrl?: string) {
  return (
    <VStack>
      <HStack>
        <Text>{`${isA ? 'Token A' : 'Token B'} ${symbol ?? 'n/a'}`}</Text>
        {iconUrl ? (
          <StyledTokenIcon
            fallback={<Skeleton borderRadius="50px" w="32px" h="32px" />}
            src={iconUrl}
          />
        ) : (
          <WarningIcon />
        )}
      </HStack>
      <Text>{`${mint}`}</Text>
    </VStack>
  );
}
export function TokenPairCard(props: React.PropsWithChildren<TokenPairProps>) {
  return (
    <VStack width={'100%'}>
      <StyledBox>
        <Box
          padding={'30px'}
          display={'flex'}
          flexDir={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {tokenInfo(props.tokenAMint, true, props.tokenASymbol, props.tokenAIconUrl)}
          {tokenInfo(props.tokenBMint, false, props.tokenBSymbol, props.tokenBIconUrl)}
        </Box>
        <Box height={'10'} />
        {props.children}
      </StyledBox>
    </VStack>
  );
}

const StyledBox = styled(Box)`
  width: 100%;
`;

const StyledTokenIcon = styled(Image)`
  width: 30px;
  border-radius: 50px;
`;
