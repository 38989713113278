import { Configuration, DefaultApi } from '@dcaf-labs/drip-ts';
import { useMemo } from 'react';
import { getClientEnv, getNetwork } from '../utils/env';
import { getApiUrl } from '../utils/url';

export function useDefaultAPI(): DefaultApi {
  const network = getNetwork();
  const client = getClientEnv();
  const url = getApiUrl(network, client);

  const config = new Configuration({
    basePath: url
  });
  return useMemo(() => new DefaultApi(config), []);
}
