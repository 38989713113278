import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import { FC, useCallback, useState } from 'react';
import { useDripContext } from '../../contexts/DripContext';
import { useNetwork } from '../../contexts/NetworkContext';
import { useTxToast } from '../../hooks/TxToast';

export const InitProtoConfig: FC = () => {
  const [granularity, setGranularity] = useState<BN | undefined>(undefined);
  const [tokenADripTriggerSpread, setTriggerDcaSpread] = useState<number | undefined>(undefined);
  const [tokenBWithdrawalSpread, setBaseWithdrawalSpread] = useState<number | undefined>(undefined);
  const [tokenBReferralSpread, setTokenBReferralSpread] = useState<number | undefined>(undefined);
  const [admin, setAdmin] = useState<PublicKey | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const txToast = useTxToast();
  const drip = useDripContext();
  const network = useNetwork();

  const deployVaultProtoConfig = useCallback(async () => {
    if (!drip) throw new Error('Drip SDK is undefined');
    console.log(granularity, tokenADripTriggerSpread, tokenBWithdrawalSpread);
    if (
      !granularity ||
      !admin ||
      tokenADripTriggerSpread === undefined ||
      tokenBWithdrawalSpread === undefined ||
      tokenBReferralSpread === undefined
    ) {
      throw new Error('undefined inputs');
    }
    try {
      setIsLoading(true);
      const txInfo = await drip.admin.initVaultProtoConfig({
        granularity,
        tokenADripTriggerSpread,
        tokenBWithdrawalSpread,
        tokenBReferralSpread,
        admin
      });
      txToast.success(txInfo);
    } catch (err) {
      txToast.failure(err as Error);
    }
    setIsLoading(false);
  }, [granularity, tokenADripTriggerSpread, tokenBWithdrawalSpread, admin, drip, network]);

  function handleGranularityChange(amount: string) {
    console.log('new granularity', amount);
    setGranularity(amount ? new BN(amount) : undefined);
  }

  function handleTriggerDCASpread(_: string, amount: number) {
    console.log('new triggerDCASpread', amount);
    setTriggerDcaSpread(amount ? amount : undefined);
  }

  function handleBaseWithdrawalSpread(_: string, amount: number) {
    console.log('new tokenBWithdrawalSpread', amount);
    setBaseWithdrawalSpread(amount ? amount : undefined);
  }

  function handleTokenBWReferralSpread(_: string, amount: number) {
    console.log('new tokenBWithdrawalSpread', amount);
    setTokenBReferralSpread(amount ? amount : undefined);
  }

  return (
    <Center>
      <Box w="100%">
        <Box>
          <FormControl>
            <FormLabel htmlFor="granularity">Granularity</FormLabel>
            <NumberInput
              id="granularity"
              value={granularity?.toString()}
              onChange={handleGranularityChange}
            >
              <NumberInputField placeholder="60" />
            </NumberInput>

            <Box mt="10px" />

            <FormLabel htmlFor="tokenADripTriggerSpread">Trigger DCA Spread</FormLabel>
            <NumberInput
              id="tokenADripTriggerSpread"
              value={tokenADripTriggerSpread?.toString()}
              onChange={handleTriggerDCASpread}
            >
              <NumberInputField placeholder="50" />
            </NumberInput>

            <Box mt="10px" />

            <FormLabel htmlFor="tokenBWithdrawalSpread">Withdrawal Spread</FormLabel>
            <NumberInput
              id="tokenBWithdrawalSpread"
              value={tokenBWithdrawalSpread?.toString()}
              onChange={handleBaseWithdrawalSpread}
            >
              <NumberInputField placeholder="25" />
            </NumberInput>

            <FormLabel htmlFor="tokenBReferralSpread">Referral Spread</FormLabel>
            <NumberInput
              id="tokenBReferralSpread"
              value={tokenBReferralSpread?.toString()}
              onChange={handleTokenBWReferralSpread}
            >
              <NumberInputField placeholder="25" />
            </NumberInput>

            <FormLabel htmlFor="admin">Admin</FormLabel>
            <InputGroup>
              <Input
                disabled={isLoading}
                id="admin"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setAdmin(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={admin?.toBase58() || ''}
                placeholder="3CTkqdcjzn1ptnNYUcFq4Sk1Smy91kz5p9JhgJBHGe3e"
              />
            </InputGroup>
            <Button w="100%" onClick={() => setAdmin(drip?.provider.wallet.publicKey)}>
              Fill Wallet
            </Button>
          </FormControl>
        </Box>
        <Box mt="10px">
          <Button
            w="100%"
            onClick={deployVaultProtoConfig}
            disabled={
              !granularity ||
              !admin ||
              tokenADripTriggerSpread === undefined ||
              tokenBWithdrawalSpread === undefined ||
              tokenBReferralSpread === undefined ||
              isLoading
            }
          >
            Deploy VaultProtoConfig
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
