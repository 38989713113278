import {
  Box,
  Center,
  Spinner,
  VStack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';
import { useDefaultAPI } from '../hooks/DefaultApi';
import { useTxToast } from '../hooks/TxToast';

export const ProtoConfigs: FC = () => {
  const api = useDefaultAPI();
  const txToast = useTxToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const protoConfigs = useAsyncMemo(async () => {
    setIsLoading(true);
    let protoConfigs;
    try {
      protoConfigs = await api.v1ProtoconfigsGet({});
    } catch (e) {
      txToast.failure(e as Error);
    }

    setIsLoading(false);
    return protoConfigs;
  }, [api, txToast]);

  if (isLoading) {
    return (
      <Center h="calc(100vh - 180px)">
        <VStack spacing="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#62aaff" size="xl" />
          <Center w="100%">
            <Text>Loading Proto Configs</Text>
          </Center>
        </VStack>
      </Center>
    );
  }

  return (
    <Box width={'100%'}>
      <Box mt="10px" />
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Public Key</Th>
              <Th>Admin</Th>
              <Th isNumeric>Granularity</Th>
              <Th isNumeric>Token A Drip Spread</Th>
              <Th isNumeric>Token B Withdrawal Spread</Th>
              <Th isNumeric>Token B Referral Spread</Th>
            </Tr>
          </Thead>
          <Tbody>
            {protoConfigs?.map((protoConfig) => {
              return (
                <Tr>
                  <Td>{protoConfig.pubkey}</Td>
                  <Td>{protoConfig.admin}</Td>
                  <Td isNumeric>{protoConfig.granularity}</Td>
                  <Td isNumeric>{protoConfig.tokenADripTriggerSpread}</Td>
                  <Td isNumeric>{protoConfig.tokenBWithdrawalSpread}</Td>
                  <Td isNumeric>{protoConfig.tokenBReferralSpread}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
