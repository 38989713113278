import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';
import { useAuthContext } from '../contexts';
import { useAdminApi } from '../hooks/AdminApi';
import { useTxToast } from '../hooks/TxToast';

export const ActiveWallets: FC = () => {
  const adminApi = useAdminApi();
  const auth = useAuthContext();
  const txToast = useTxToast();

  const [filterVault, setFilterVault] = useState<string | undefined>(undefined);
  const [filterOwner, setFilterOwner] = useState<string | undefined>(undefined);
  const [filterIsClosed, setFilterIsClosed] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const activeWallets = useAsyncMemo(async () => {
    setIsLoading(true);
    let activeWallets;
    try {
      activeWallets = await adminApi.v1AdminSummaryActivewalletsGet({
        tokenId: auth.tokenId ?? '',
        vault: filterVault,
        isClosed: filterIsClosed,
        owner: filterOwner
      });
    } catch (e) {
      txToast.failure(e as Error);
    }

    setIsLoading(false);
    return activeWallets;
  }, [adminApi, auth, filterVault, filterOwner, filterIsClosed]);

  if (isLoading) {
    return (
      <Center h="calc(100vh - 180px)">
        <VStack spacing="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#62aaff" size="xl" />
          <Center w="100%">
            <Text>Loading Vaults</Text>
          </Center>
        </VStack>
      </Center>
    );
  }

  return (
    <Box width={'100%'}>
      <Accordion allowToggle={true}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by vault"
              size="lg"
              value={filterVault}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if ((e.target as HTMLInputElement).value === '') {
                  setFilterVault(undefined);
                } else {
                  setFilterVault((e.target as HTMLInputElement).value ?? undefined);
                }
              }}
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by token a mint"
              size="lg"
              value={filterOwner}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if ((e.target as HTMLInputElement).value === '') {
                  setFilterOwner(undefined);
                } else {
                  setFilterOwner((e.target as HTMLInputElement).value ?? undefined);
                }
              }}
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <RadioGroup
              onChange={(val: string | number | undefined) => {
                if (val === '1') {
                  setFilterIsClosed(undefined);
                } else if (val === '2') {
                  setFilterIsClosed(true);
                } else {
                  setFilterIsClosed(false);
                }
              }}
              value={filterIsClosed === undefined ? '1' : filterIsClosed ? '2' : '3'}
            >
              <Stack direction="row">
                <Radio value={'1'}>All</Radio>
                <Radio value={'2'}>Closed</Radio>
                <Radio value={'3'}>Open</Radio>
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Box mt="10px" />
      {activeWallets?.length === 0 && (
        <Center h="calc(100vh - 177px)">
          <Text fontSize="26px" fontWeight="medium">
            No Active Wallets
          </Text>
        </Center>
      )}
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Owner</Th>
              <Th isNumeric>Position Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            {activeWallets?.map((activeWallet) => {
              return (
                <Tr>
                  <Td>{activeWallet.owner}</Td>
                  <Td isNumeric>{activeWallet.positionCount}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
