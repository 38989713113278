import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Stack,
  RadioGroup,
  Radio,
  IconButton,
  Text,
  HStack
} from '@chakra-ui/react';
import { Action, ActionProps, actions, useActionContext } from '../contexts/ActionContext';

export function ActionDrawer({
  actionContext
}: {
  actionContext: { action?: Action; props?: ActionProps };
}) {
  const { setActionContext: setAction } = useActionContext();
  const { onClose } = useDisclosure();

  const onDrawerClose = () => {
    setAction({});
    onClose();
  };

  const back = () => {
    return (
      <IconButton
        onClick={() => setAction({ action: Action.menu })}
        icon={<ArrowBackIcon />}
        aria-label={'Toggle Navigation'}
      />
    );
  };

  const renderDrawerContent = () => {
    if (actionContext.action === undefined) {
      return (
        <>
          <DrawerHeader>
            <HStack>
              {back()} <Text>No Action Defined</Text>
            </HStack>
          </DrawerHeader>
        </>
      );
    }

    if (actionContext.action === Action.menu) {
      return (
        <>
          <DrawerHeader>Choose an Action</DrawerHeader>
          <DrawerBody>
            <RadioGroup
              onChange={(val: string) => {
                const typedVal = val as keyof typeof Action;
                setAction({ action: Action[typedVal] });
              }}
            >
              <Stack direction="column">
                {Object.values(actions).map((cfg, key) => {
                  return <Radio value={Action[key]}>{cfg.title}</Radio>;
                })}
              </Stack>
            </RadioGroup>
          </DrawerBody>
        </>
      );
    }

    const actionConfig = actions[actionContext.action ?? 0];
    return (
      <>
        <DrawerHeader>
          <HStack>
            {back()} <Text>{actionConfig.title}</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody>{actionConfig.component(actionContext.props)}</DrawerBody>
      </>
    );
  };

  return (
    <Drawer
      isOpen={actionContext !== undefined}
      placement="right"
      onClose={onDrawerClose}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {renderDrawerContent()}
      </DrawerContent>
    </Drawer>
  );
}
