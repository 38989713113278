import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import BN from 'bn.js';
import { FC, useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';
import { useAuthContext } from '../contexts';
import { useAdminApi } from '../hooks/AdminApi';
import { useTxToast } from '../hooks/TxToast';
import { formatTokenAmount } from '../utils/token-amount';

export const Positions: FC = () => {
  const adminApi = useAdminApi();
  const auth = useAuthContext();
  const txToast = useTxToast();

  const [filterEnabled, setFilterEnabled] = useState<boolean | undefined>(undefined);
  const [filterIsClosed, setFilterIsClosed] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const positions = useAsyncMemo(async () => {
    setIsLoading(true);
    let positions;
    try {
      positions = await adminApi.v1AdminPositionsGet({
        tokenId: auth.tokenId ?? '',
        enabled: filterEnabled ? filterEnabled : undefined,
        isClosed: filterIsClosed ? filterIsClosed : undefined,
        expand: ['all']
        // offset?: number;
        // limit?: number;
      });
    } catch (e) {
      txToast.failure(e as Error);
    }

    setIsLoading(false);
    return positions;
  }, [adminApi, auth, filterEnabled, filterIsClosed]);

  if (isLoading) {
    return (
      <Center h="calc(100vh - 180px)">
        <VStack spacing="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#62aaff" size="xl" />
          <Center w="100%">
            <Text>Loading Vaults</Text>
          </Center>
        </VStack>
      </Center>
    );
  }
  return (
    <Box width={'100%'}>
      <Accordion allowToggle={true}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel pb={4}>
            <RadioGroup
              onChange={(val: string | number | undefined) => {
                if (val === '1') {
                  setFilterIsClosed(undefined);
                } else if (val === '2') {
                  setFilterIsClosed(true);
                } else {
                  setFilterIsClosed(false);
                }
              }}
              value={filterIsClosed === undefined ? '1' : filterIsClosed ? '2' : '3'}
            >
              <Stack direction="row">
                <Radio value={'1'}>All</Radio>
                <Radio value={'2'}>Closed</Radio>
                <Radio value={'3'}>Open</Radio>
              </Stack>
            </RadioGroup>
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <RadioGroup
              onChange={(val: string | number | undefined) => {
                if (val === '1') {
                  setFilterEnabled(undefined);
                } else if (val === '2') {
                  setFilterEnabled(true);
                } else {
                  setFilterEnabled(false);
                }
              }}
              value={filterEnabled === undefined ? '1' : filterEnabled ? '2' : '3'}
            >
              <Stack direction="row">
                <Radio value={'1'}>All</Radio>
                <Radio value={'2'}>Enabled</Radio>
                <Radio value={'3'}>Disabled</Radio>
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Box mt="10px" />
      {positions?.length === 0 && (
        <Center h="calc(100vh - 177px)">
          <Text fontSize="26px" fontWeight="medium">
            No Positions
          </Text>
        </Center>
      )}
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Public Key</Th>
              <Th>Deposit Timestamp</Th>
              <Th>Token A</Th>
              <Th>Scaled?</Th>
              <Th isNumeric>Deposit (A)</Th>
              <Th isNumeric>Drip Amount (A)</Th>
              <Th>Token B</Th>
              <Th>Scaled?</Th>
              <Th isNumeric>Withdrawn (B)</Th>
              <Th isNumeric>Number of swaps</Th>
              <Th>Is Closed</Th>
              <Th isNumeric>Deposit Period ID</Th>
              <Th>Authority</Th>
              <Th>Vault</Th>
            </Tr>
          </Thead>
          <Tbody>
            {positions?.map((position) => {
              return (
                <Tr>
                  <Td>{position.pubkey}</Td>

                  <Td>{new Date(Number(position.depositTimestamp) * 1000).toString()}</Td>

                  <Td>{position.tokenA?.symbol ?? 'n/a'}</Td>

                  <Td>{position.tokenA?.decimals ? 'yes' : 'no'}</Td>

                  <Td>
                    {formatTokenAmount(
                      new BN(position.depositedTokenAAmount),
                      position.tokenA?.decimals ?? 1,
                      true
                    )}
                  </Td>

                  <Td>
                    {formatTokenAmount(
                      new BN(position.periodicDripAmount),
                      position.tokenA?.decimals ?? 1,
                      true
                    )}
                  </Td>

                  <Td>{position.tokenB?.symbol ?? 'n/a'}</Td>

                  <Td>{position.tokenB?.decimals ? 'yes' : 'no'}</Td>

                  <Td>
                    {formatTokenAmount(
                      new BN(position.withdrawnTokenBAmount),
                      position.tokenB?.decimals ?? 1,
                      true
                    )}
                  </Td>

                  <Td>{position.numberOfSwaps}</Td>

                  <Td>{position.isClosed ? 'yes' : 'no'}</Td>

                  <Td>{position.dcaPeriodIdBeforeDeposit}</Td>

                  <Td>{position.authority}</Td>

                  <Td>{position.vault.pubkey}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
