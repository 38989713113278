import { ChakraProvider, Skeleton } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Nav from './component/Navbar';
import { AuthContext } from './contexts/AdminAuth';
import { DripContext } from './contexts/DripContext';
import { useDrip } from './hooks/Drip';
import { ActiveWallets } from './pages/ActiveWallets';
import { Positions } from './pages/Positions';
import { Vaults } from './pages/Vaults';
import { Vault } from './pages/Vault';
import { theme } from './theme';
import { ProtoConfigs } from './pages/ProtoConfigs';
import { RefreshContext } from './contexts/Refresh';
import { useStateRefresh } from './hooks/StateRefresh';
import { Action, ActionContext, ActionProps } from './contexts/ActionContext';
import { ActionDrawer } from './pages/ActionDrawer';

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const StyledAppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
`;

const App: FC = () => {
  const drip = useDrip();
  const [tokenId, setTokenId] = useState<string | undefined>();
  const [refreshTrigger, forceRefresh] = useStateRefresh();
  const [actionContext, setActionContext] = useState<{ action?: Action; props?: ActionProps }>({});

  return (
    <ChakraProvider theme={theme}>
      <DripContext.Provider value={drip}>
        <AuthContext.Provider
          value={{
            tokenId,
            setTokenId
          }}
        >
          <RefreshContext.Provider value={{ refreshTrigger, forceRefresh }}>
            <ActionContext.Provider value={{ setActionContext }}>
              <Nav />
              <StyledAppContainer>
                {actionContext.action !== undefined && (
                  <ActionDrawer actionContext={actionContext} />
                )}
                {tokenId && actionContext?.action !== undefined && (
                  <Skeleton width={'100%'} height={'100%'} />
                )}

                {tokenId && actionContext?.action === undefined && (
                  <Routes>
                    <Route path="/vaults" element={<Vaults />} />
                    <Route path="/vaults/:pubkey" element={<Vault />} />

                    <Route path="/protoconfigs" element={<ProtoConfigs />} />
                    <Route path="/positions" element={<Positions />} />
                    <Route path="/activewallets" element={<ActiveWallets />} />
                    <Route path="*" element={<Navigate to="/vaults" />} />
                  </Routes>
                )}
                {!tokenId && <div>Please Login</div>}
              </StyledAppContainer>
            </ActionContext.Provider>
          </RefreshContext.Provider>
        </AuthContext.Provider>
      </DripContext.Provider>
    </ChakraProvider>
  );
};
export default App;
