import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Stack
} from '@chakra-ui/react';
import { makeExplorerUrl } from '@dcaf-labs/drip-sdk';
import { PublicKey } from '@solana/web3.js';
import { FC, useCallback, useState } from 'react';
import { useDripContext } from '../../contexts/DripContext';
import { useNetwork } from '../../contexts/NetworkContext';
import { useTxToast } from '../../hooks/TxToast';
import { Address } from '@project-serum/anchor';
import { toPubkey } from '../../utils/pubkey';
import { useWallet } from '@solana/wallet-adapter-react';

export interface InitOracleConfigProps {
  tokenAMint?: Address;
  tokenBMint?: Address;
}

export const InitOracleConfig: FC<InitOracleConfigProps> = (props: InitOracleConfigProps) => {
  const txToast = useTxToast();
  const drip = useDripContext();
  const network = useNetwork();
  const wallet = useWallet();
  const [tokenAMint, setTokenAMint] = useState<PublicKey | undefined>(
    props.tokenAMint ? toPubkey(props.tokenAMint) : undefined
  );
  const [tokenAPrice, setTokenAPrice] = useState<PublicKey | undefined>();
  const [tokenBMint, setTokenBMint] = useState<PublicKey | undefined>(
    props.tokenBMint ? toPubkey(props.tokenBMint) : undefined
  );
  const [tokenBPrice, setTokenBPrice] = useState<PublicKey | undefined>();
  const [updateAuthority, setUpdateAuthority] = useState<PublicKey | undefined>(
    wallet.publicKey ?? undefined
  );
  const [enabled, setEnabled] = useState<boolean>(true);
  const [source, setSource] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const deployOracleConfig = useCallback(async () => {
    if (!drip) throw new Error('Drip SDK is undefined');
    if (!tokenAMint || !tokenBMint || !updateAuthority || !tokenAPrice || !tokenBPrice) {
      throw new Error('undefined inputs');
    }
    setIsLoading(true);

    const initOracleConfigPreview = await drip.admin.getInitOracleConfigPreview({
      tokenAMint,
      tokenAPrice,
      tokenBMint,
      tokenBPrice,
      updateAuthority,
      enabled,
      source
    });
    const txWithMetadata = await drip.admin.getInitOracleProtoConfigTx(initOracleConfigPreview);

    try {
      const txHash = await drip.provider.sendAndConfirm(txWithMetadata.tx);
      const txInfo = {
        id: txHash,
        explorer: makeExplorerUrl(txHash, network),
        metadata: txWithMetadata.metadata
      };
      txToast.success(txInfo);
    } catch (err) {
      txToast.failure(err as Error);
    }
    setIsLoading(false);
  }, [
    tokenAMint,
    tokenAPrice,
    tokenBMint,
    tokenBPrice,
    updateAuthority,
    enabled,
    source,
    drip,
    network
  ]);

  return (
    <Center>
      <Box w="100%">
        <Box>
          <FormControl>
            <FormLabel htmlFor="tokenAMint">Token A Mint</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenAMint"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setTokenAMint(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setTokenAMint(undefined);
                  console.error(e);
                }
              }}
              value={tokenAMint?.toBase58() || ''}
              placeholder="Token A Mint"
            />

            <Box mt="10px" />

            <FormLabel htmlFor="tokenAPrice">Token A Price</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenAPrice"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setTokenAPrice(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setTokenAPrice(undefined);
                  console.error(e);
                }
              }}
              value={tokenAPrice?.toBase58() || ''}
              placeholder="Token A Price Account"
            />

            <Box mt="10px" />

            <FormLabel htmlFor="tokenBMint">Token B Mint</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenBMint"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                console.log((e.target as HTMLInputElement).value);
                try {
                  setTokenBMint(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  console.error(e);
                  setTokenBMint(undefined);
                }
              }}
              value={tokenBMint?.toBase58() || ''}
              placeholder="Token B Mint"
            />

            <Box mt="10px" />

            <FormLabel htmlFor="tokenBPrice">Token B Price</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenBPrice"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setTokenBPrice(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setTokenBPrice(undefined);
                  console.error(e);
                }
              }}
              value={tokenBPrice?.toBase58() || ''}
              placeholder="Token B Price Account"
            />

            <Box mt="10px" />

            <FormLabel htmlFor="updateAuthority">Update Authority</FormLabel>
            <InputGroup>
              <Input
                disabled={isLoading}
                id="updateAuthority"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setUpdateAuthority(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    setUpdateAuthority(undefined);
                    console.error(e);
                  }
                }}
                value={updateAuthority?.toBase58() || ''}
                placeholder="Update Authority Address"
              />
            </InputGroup>

            <Box mt="10px" />

            <FormLabel htmlFor="enabled">Enabled</FormLabel>

            <RadioGroup
              id="enabled"
              onChange={(val: string | number | undefined) => {
                if (val === '0') {
                  setEnabled(false);
                } else if (val === '1') {
                  setEnabled(true);
                }
              }}
              value={enabled ? '1' : '0'}
            >
              <Stack direction="row" alignItems={'center'}>
                <Radio value={'0'}>Disabled</Radio>
                <Radio value={'1'}>Enabled</Radio>
              </Stack>
            </RadioGroup>

            <Box mt="10px" />

            <FormLabel htmlFor="source">Source</FormLabel>

            <RadioGroup
              id="source"
              onChange={(val: string | number | undefined) => {
                setSource(val !== undefined ? parseInt(val.toString(), 10) : 0);
              }}
              value={source.toString()}
            >
              <Stack direction="row" alignItems={'center'}>
                <Radio value={'0'}>Pyth</Radio>
              </Stack>
            </RadioGroup>

            <Box mt="10px" />
          </FormControl>
        </Box>
        <Box mt="10px">
          <Button w="100%" onClick={deployOracleConfig}>
            Deploy Oracle Config
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
