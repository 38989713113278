import { Drip } from '@dcaf-labs/drip-sdk';
import { Network } from '@dcaf-labs/drip-sdk/dist/models';
import { AnchorProvider } from '@project-serum/anchor';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useMemo } from 'react';
import { getClientEnv, getNetwork, getProgramId } from '../utils/env';

export class DefaultWallet {
  publicKey: PublicKey = PublicKey.default;

  signTransaction(): Promise<Transaction> {
    throw new Error('Default wallet cannot sign');
  }
  signAllTransactions(): Promise<Transaction[]> {
    throw new Error('Default wallet cannot sign');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isDefaultWallet(wallet: any) {
    return wallet.publicKey === PublicKey.default;
  }
}

const defaultWallet = new DefaultWallet();

export function useDrip(): Drip {
  const wallet = useWallet();
  const anchorWallet = useAnchorWallet();

  const network = getNetwork();

  return useMemo(() => {
    const clusterUrl =
      network === Network.Mainnet
        ? 'https://palpable-warmhearted-hexagon.solana-mainnet.discover.quiknode.pro/5793cf44e6e16325347e62d571454890f16e0388'
        : 'https://wiser-icy-bush.solana-devnet.discover.quiknode.pro/7288cc56d980336f6fc0508eb1aa73e44fd2efcd';
    const drip = Drip.fromNetworkClient(
      network,
      new AnchorProvider(
        new Connection(clusterUrl, 'confirmed'),
        anchorWallet ?? defaultWallet,
        AnchorProvider.defaultOptions()
      ),
      getProgramId(),
      getClientEnv()
    );
    console.log('connected to program', drip.programId.toString());
    return drip;
  }, [wallet, anchorWallet]);
}
