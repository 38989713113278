import { Box, Button, Center, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { makeExplorerUrl } from '@dcaf-labs/drip-sdk';
import { PublicKey } from '@solana/web3.js';
import { FC, useCallback, useState } from 'react';
import { useDripContext } from '../../contexts/DripContext';
import { useNetwork } from '../../contexts/NetworkContext';
import { useTxToast } from '../../hooks/TxToast';
import { Address } from '@project-serum/anchor';
import { toPubkey } from '../../utils/pubkey';

export interface SetVaultOracleConfigProps {
  vault?: Address;
}

export const SetVaultOracleConfig: FC<SetVaultOracleConfigProps> = (
  props: SetVaultOracleConfigProps
) => {
  const txToast = useTxToast();
  const drip = useDripContext();
  const network = useNetwork();
  const [vault, setVault] = useState<PublicKey | undefined>(
    props.vault ? toPubkey(props.vault) : undefined
  );
  const [newOracleConfig, setNewOracleConfig] = useState<PublicKey | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const setVaultOracleConfig = useCallback(async () => {
    if (!drip) throw new Error('Drip SDK is undefined');
    if (!vault || !newOracleConfig) {
      throw new Error('undefined inputs');
    }
    setIsLoading(true);

    const txWithMetadata = await drip.admin.getSetVaultOracleConfigTx({
      vault,
      newOracleConfig
    });

    try {
      const txHash = await drip.provider.sendAndConfirm(txWithMetadata.tx);
      const txInfo = {
        id: txHash,
        explorer: makeExplorerUrl(txHash, network),
        metadata: txWithMetadata.metadata
      };
      txToast.success(txInfo);
    } catch (err) {
      txToast.failure(err as Error);
    }
    setIsLoading(false);
  }, [vault, newOracleConfig, drip, network]);

  return (
    <Center>
      <Box w="100%">
        <Box>
          <FormControl>
            <FormLabel htmlFor="vault">Vault</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="vault"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setVault(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setVault(undefined);
                  console.error(e);
                }
              }}
              value={vault?.toBase58() || ''}
              placeholder="Vault Pubkey"
            />

            <Box mt="10px" />

            <FormLabel htmlFor="newOracleConfig">New Oracle Config</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="newOracleConfig"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setNewOracleConfig(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setNewOracleConfig(undefined);
                  console.error(e);
                }
              }}
              value={newOracleConfig?.toBase58() || ''}
              placeholder="New Oracle Config Pubkey"
            />

            <Box mt="10px" />
          </FormControl>
        </Box>
        <Box mt="10px">
          <Button w="100%" onClick={setVaultOracleConfig}>
            Set Vault Oracle Config
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
