import { AdminApi, Configuration } from '@dcaf-labs/drip-ts';
import { useMemo } from 'react';
import { useAuthContext } from '../contexts';
import { getClientEnv, getNetwork } from '../utils/env';
import { getApiUrl } from '../utils/url';

export function useAdminApi(): AdminApi {
  const auth = useAuthContext();

  const network = getNetwork();
  const client = getClientEnv();
  const url = getApiUrl(network, client);

  const config = new Configuration({
    basePath: url,
    headers: {
      'token-id': auth.tokenId ?? ''
    }
  });
  return useMemo(() => new AdminApi(config), [auth]);
}
