import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useNetwork } from '../contexts/NetworkContext';
import { Network } from '@dcaf-labs/drip-sdk/dist/models';

export const NetworkBadge: FC = () => {
  const network = useNetwork();
  return (
    <Button
      disabled={true}
      display={{ base: 'none', sm: 'inline-flex' }}
      fontWeight={600}
      colorScheme="brand"
      color={'white'}
    >
      {Network[network].toUpperCase()}
    </Button>
  );
};
