import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Box } from '@chakra-ui/react';
import { ExpandedAdminVault } from '@dcaf-labs/drip-ts';
import BN from 'bn.js';
import { FC } from 'react';
import { formatTokenAmount } from '../utils/token-amount';
import { EnableVault } from './EnableVaultButton';
import { SetVaultOracleConfigButton } from './SetVaultOracleConfigButton';

export interface VaultsTableProps {
  vaults: ExpandedAdminVault[];
}

export const VaultsTable: FC<VaultsTableProps> = ({ vaults }: VaultsTableProps) => {
  vaults.sort((a: ExpandedAdminVault, b: ExpandedAdminVault): number => {
    if (a.protoConfigValue?.granularity === b.protoConfigValue?.granularity) {
      return 0;
    } else if (
      Number(a.protoConfigValue?.granularity ?? 0) < Number(b.protoConfigValue?.granularity ?? 0)
    ) {
      return -1;
    } else {
      return 1;
    }
  });
  return (
    <Box width={'100%'}>
      <TableContainer overflowX="unset">
        <Table size="sm" variant="simple">
          <Thead position="sticky" top={0} zIndex="docked">
            <Tr>
              <Th>Toggle Enagled</Th>
              <Th>Public Key</Th>
              <Th>Proto Config</Th>
              <Th isNumeric>Granularity</Th>
              <Th>Oracle Config</Th>
              <Th>Admin</Th>
              <Th>Treasury Owner</Th>
              <Th isNumeric>Token A Balance</Th>
              <Th isNumeric>Token B Balance</Th>
              <Th>Next Drip</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vaults?.map((expandedAdminVault: ExpandedAdminVault) => {
              const nextDrip = new Date(Number(expandedAdminVault.dcaActivationTimestamp) * 1000);
              return (
                <Tr key={expandedAdminVault.pubkey}>
                  <Td>
                    <EnableVault
                      vault={expandedAdminVault.pubkey}
                      vaultEnabled={expandedAdminVault.enabled}
                    />
                  </Td>
                  <Td>{expandedAdminVault.pubkey}</Td>

                  <Td>{expandedAdminVault.protoConfig}</Td>
                  <Td isNumeric>{expandedAdminVault.protoConfigValue?.granularity ?? 'n/a'}</Td>

                  <Td>
                    {expandedAdminVault.oracleConfig ?? (
                      <SetVaultOracleConfigButton vault={expandedAdminVault.pubkey} />
                    )}
                  </Td>

                  <Td>{expandedAdminVault.protoConfigValue?.admin ?? 'n/a'}</Td>

                  <Td>{expandedAdminVault.treasuryTokenBAccountValue?.owner ?? 'n/a'}</Td>

                  <Td isNumeric>
                    {expandedAdminVault.tokenAMintValue?.decimals &&
                    expandedAdminVault.tokenAAccountValue?.amount
                      ? formatTokenAmount(
                          new BN(expandedAdminVault.tokenAAccountValue.amount),
                          expandedAdminVault.tokenAMintValue.decimals,
                          true
                        )
                      : 'n/a'}
                  </Td>

                  <Td isNumeric>
                    {expandedAdminVault.tokenBMintValue?.decimals &&
                    expandedAdminVault.tokenBAccountValue?.amount
                      ? formatTokenAmount(
                          new BN(expandedAdminVault.tokenBAccountValue.amount),
                          expandedAdminVault.tokenBMintValue.decimals,
                          true
                        )
                      : 'n/a'}
                  </Td>

                  <Td>{`${nextDrip.toLocaleDateString()} ${nextDrip.toLocaleTimeString()}`}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
