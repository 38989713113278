import { Network, ClientEnv } from '@dcaf-labs/drip-sdk';

export function getApiUrl(network: Network, clientEnv: ClientEnv): string {
  switch (network) {
    case Network.Mainnet:
      return 'https://api.drip.dcaf.so';
    case Network.Devnet:
      switch (clientEnv) {
        case ClientEnv.Production:
          return 'https://devnet.api.drip.dcaf.so';
        case ClientEnv.Staging:
          return 'https://drip-backend-devnet-staging.herokuapp.com';
      }
  }
  return 'localhost';
}

export function formatPubkey(pubkey: string): string {
  return pubkey.slice(0, 4) + '...' + pubkey.slice(pubkey.length - 4, pubkey.length);
}
