import { Box, Button, ButtonProps, Tooltip, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useAuthContext } from '../contexts';
import { useRefreshContext } from '../contexts/Refresh';
import { useAdminApi } from '../hooks/AdminApi';

interface EnableVaultButtonProps {
  vault: string;
  vaultEnabled: boolean;
}

export function EnableVault({
  vault,
  vaultEnabled,
  disabled,
  ...buttonProps
}: EnableVaultButtonProps & ButtonProps) {
  const refreshContext = useRefreshContext();
  const adminApi = useAdminApi();
  const auth = useAuthContext();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleEnableVault() {
    setIsLoading(true);
    let updatedVault;
    try {
      updatedVault = await adminApi.v1AdminVaultPubkeyPathEnablePut({
        tokenId: auth.tokenId ?? '',
        pubkeyPath: vault
      });
    } catch (e) {
      console.error(`Error during get: ${e}`);
      toast({
        title: 'Vault Enable Failed',
        description: (e as Error).message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }
    toast({
      title: updatedVault?.enabled ? 'Enbled Vault' : 'Disabled Vault',
      description: <Box>{vault}</Box>,
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-right'
    });

    setIsLoading(false);
    refreshContext.forceRefresh();
    return updatedVault;
  }

  return (
    <Tooltip label={vaultEnabled ? `Disable Vault ${vault}` : `Enable Vault ${vault}`}>
      <Button
        isLoading={isLoading}
        width={'100%'}
        disabled={disabled || isLoading}
        {...buttonProps}
        aria-label="Enable Vault"
        // icon={isLoading ? <Spinner /> : <InfoIcon />}
        onClick={handleEnableVault}
        color={vaultEnabled ? 'red' : 'green'}
      >
        {vaultEnabled ? 'Disable Vault' : 'Enable Vault'}
      </Button>
    </Tooltip>
  );
}
