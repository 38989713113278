import {
  Box,
  Button,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup
} from '@chakra-ui/react';
import { makeExplorerUrl } from '@dcaf-labs/drip-sdk';
import { PublicKey, Transaction } from '@solana/web3.js';
import { FC, useCallback, useState } from 'react';
import {
  createAssociatedTokenAccountInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError
} from '@solana/spl-token';
import { useDripContext } from '../../contexts/DripContext';
import { useNetwork } from '../../contexts/NetworkContext';
import { useTxToast } from '../../hooks/TxToast';
import { Address } from '@project-serum/anchor';
import { toPubkey } from '../../utils/pubkey';

export interface InitVaultProps {
  tokenAMint?: Address;
  tokenBMint?: Address;
  protoConfig?: Address;
  whitelistedSwaps?: Address[];
}

export const InitVault: FC<InitVaultProps> = (props: InitVaultProps) => {
  const [tokenAMint, setTokenAMint] = useState<PublicKey | undefined>(
    props.tokenAMint ? toPubkey(props.tokenAMint) : undefined
  );
  const [tokenBMint, setTokenBMint] = useState<PublicKey | undefined>(
    props.tokenBMint ? toPubkey(props.tokenBMint) : undefined
  );
  const [treasuryAccountOwner, setTreasuryAccountOwner] = useState<PublicKey | undefined>();
  const [treasuryAccount, setTreasuryAccount] = useState<PublicKey | undefined>();
  const [protoConfig, setProtoConfig] = useState<PublicKey | undefined>(
    props.protoConfig ? toPubkey(props.protoConfig) : undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  // Gonna KMS
  const [whiteList1, setWhitelist1] = useState<PublicKey | undefined>(
    props.whitelistedSwaps?.[0] ? toPubkey(props.whitelistedSwaps[0]) : undefined
  );
  const [whiteList2, setWhitelist2] = useState<PublicKey | undefined>(
    props.whitelistedSwaps?.[1] ? toPubkey(props.whitelistedSwaps[0]) : undefined
  );
  const [whiteList3, setWhitelist3] = useState<PublicKey | undefined>(
    props.whitelistedSwaps?.[2] ? toPubkey(props.whitelistedSwaps[0]) : undefined
  );
  const [whiteList4, setWhitelist4] = useState<PublicKey | undefined>(
    props.whitelistedSwaps?.[3] ? toPubkey(props.whitelistedSwaps[0]) : undefined
  );
  const [whiteList5, setWhitelist5] = useState<PublicKey | undefined>(
    props.whitelistedSwaps?.[4] ? toPubkey(props.whitelistedSwaps[0]) : undefined
  );

  const txToast = useTxToast();
  const drip = useDripContext();
  const network = useNetwork();

  const createOrGetTreasuryAccount = useCallback(async () => {
    console.log(tokenBMint, drip, treasuryAccountOwner);
    if (!tokenBMint) throw new Error('empty tokenBMint');
    if (!drip) throw new Error('empty drip sdk');
    if (!treasuryAccountOwner) throw new Error('empty treasuryAccountOwner');
    setIsLoading(true);

    const treasuryTokenBAccount = await getAssociatedTokenAddress(tokenBMint, treasuryAccountOwner);
    console.log(treasuryTokenBAccount.toString());
    // Get treasury account or create ATA
    try {
      // Account exists
      await getAccount(drip.provider.connection, treasuryTokenBAccount);
      setTreasuryAccount(treasuryTokenBAccount);
    } catch (error: unknown) {
      if (
        error instanceof TokenAccountNotFoundError ||
        error instanceof TokenInvalidAccountOwnerError
      ) {
        try {
          const createATAIx = createAssociatedTokenAccountInstruction(
            drip.provider.wallet.publicKey,
            treasuryTokenBAccount,
            treasuryAccountOwner,
            tokenBMint
          );
          const txHash = await drip.provider.sendAndConfirm(
            new Transaction().add(createATAIx),
            undefined
          );
          const txInfo = {
            id: txHash,
            explorer: makeExplorerUrl(txHash, network),
            metadata: undefined
          };
          txToast.success(txInfo);
          setTreasuryAccount(treasuryTokenBAccount);
        } catch (error2: unknown) {
          console.log(error2);
          setIsLoading(false);
          txToast.failure(error as Error);
          return;
        }
        // Account doesn't exist, create an ATA
      } else {
        console.log(error);
        txToast.failure(error as Error);
        return;
      }
    }
    setIsLoading(false);
  }, [
    tokenBMint,
    drip,
    treasuryAccountOwner,
    setTreasuryAccount,
    setIsLoading,
    txToast,
    whiteList1,
    whiteList2,
    whiteList3,
    whiteList4,
    whiteList5
  ]);

  const deployVault = useCallback(async () => {
    if (!drip) throw new Error('Drip SDK is undefined');
    if (!tokenAMint || !tokenBMint || !protoConfig || !treasuryAccount) {
      throw new Error('undefined inputs');
    }
    setIsLoading(true);
    const whitelistedSwaps = [whiteList1, whiteList2, whiteList3, whiteList4, whiteList5].filter(
      (address: PublicKey | undefined) => address !== undefined
    ) as PublicKey[];
    const initVaultTx = await drip.admin.getInitVaultTx({
      protoConfig,
      tokenAMint,
      tokenBMint,
      tokenBFeeTreasury: treasuryAccount,
      whitelistedSwaps,
      maxSlippageBps: 1000
    });

    try {
      const txHash = await drip.provider.sendAndConfirm(initVaultTx.tx, undefined);
      const txInfo = {
        id: txHash,
        explorer: makeExplorerUrl(txHash, network),
        metadata: initVaultTx.metadata
      };
      txToast.success(txInfo);
    } catch (err) {
      txToast.failure(err as Error);
    }
    setIsLoading(false);
  }, [tokenAMint, tokenBMint, treasuryAccount, protoConfig, drip, network]);

  return (
    <Center>
      <Box w="100%">
        <Box>
          <FormControl>
            <FormLabel htmlFor="vaultProtoConfig">Vault Proto Config</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="vaultProtoConfig"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setProtoConfig(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setProtoConfig(undefined);
                  console.error(e);
                }
              }}
              value={protoConfig?.toBase58() || ''}
              placeholder="Vault Proto Config"
            />
            <FormHelperText>Copy and paste the proto config publickey.</FormHelperText>

            <Box mt="10px" />

            <FormLabel htmlFor="tokenAMint">Token A Mint</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenAMint"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                try {
                  setTokenAMint(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  setTokenAMint(undefined);
                  console.error(e);
                }
              }}
              value={tokenAMint?.toBase58() || ''}
              placeholder="Token A Mint"
            />
            <FormHelperText>Copy and paste the token mint publickey.</FormHelperText>

            <Box mt="10px" />

            <FormLabel htmlFor="tokenBMint">Token B Mint</FormLabel>
            <Input
              disabled={isLoading}
              required
              id="tokenBMint"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                console.log((e.target as HTMLInputElement).value);
                try {
                  setTokenBMint(new PublicKey((e.target as HTMLInputElement).value));
                } catch (e) {
                  console.error(e);
                  setTokenBMint(undefined);
                }
              }}
              value={tokenBMint?.toBase58() || ''}
              placeholder="Token B Mint"
            />
            <FormHelperText>Copy and paste the token mint publickey.</FormHelperText>

            <Box mt="10px" />

            <FormLabel htmlFor="treasuryAccountOwner">Vault Token B Treasury Owner</FormLabel>
            <InputGroup>
              <Input
                disabled={isLoading}
                id="treasuryAccountOwner"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setTreasuryAccountOwner(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    setTreasuryAccountOwner(undefined);
                    console.error(e);
                  }
                }}
                value={treasuryAccountOwner?.toBase58() || ''}
                placeholder="Treasury Token B Account"
              />
            </InputGroup>
            <Button w="100%" onClick={createOrGetTreasuryAccount}>
              Fetch ATA
            </Button>

            <FormHelperText>
              Copy and paste the treasury token owner publickey.
              <br />
              An ATA will be fetched/created.
            </FormHelperText>
            <FormHelperText>{`Owner ATA: ${treasuryAccount?.toString()}`}</FormHelperText>

            <Box mt="10px" />

            <FormLabel htmlFor="vaultWhitelist">Vault Whitelists</FormLabel>

            <FormHelperText>Optional</FormHelperText>
            <InputGroup>
              <Input
                disabled={isLoading}
                id="vaultWhitelist1"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setWhitelist1(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={whiteList1?.toBase58() || ''}
                placeholder="Whitelist 1"
              />
            </InputGroup>

            <InputGroup>
              <Input
                disabled={isLoading}
                id="vaultWhitelist2"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setWhitelist2(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={whiteList2?.toBase58() || ''}
                placeholder="Whitelist 2"
              />
            </InputGroup>

            <InputGroup>
              <Input
                disabled={isLoading}
                id="vaultWhitelist3"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setWhitelist3(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={whiteList3?.toBase58() || ''}
                placeholder="Whitelist 3"
              />
            </InputGroup>

            <InputGroup>
              <Input
                disabled={isLoading}
                id="vaultWhitelist4"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setWhitelist4(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={whiteList4?.toBase58() || ''}
                placeholder="Whitelist 4"
              />
            </InputGroup>

            <InputGroup>
              <Input
                disabled={isLoading}
                id="vaultWhitelist5"
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  console.log((e.target as HTMLInputElement).value);
                  try {
                    setWhitelist5(new PublicKey((e.target as HTMLInputElement).value));
                  } catch (e) {
                    console.error(e);
                  }
                }}
                value={whiteList5?.toBase58() || ''}
                placeholder="Whitelist 5"
              />
            </InputGroup>
          </FormControl>
        </Box>
        {/* <Box mt="10px"></Box> */}
        {/* <Box mt="10px"></Box> */}
        <Box mt="10px">
          <Button w="100%" onClick={deployVault}>
            Deploy Vault
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
