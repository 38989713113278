import { Button, Image } from '@chakra-ui/react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../assets/logo.svg';
import Drip from '../assets/drip.svg';
import { useAuthContext } from '../contexts/AdminAuth';
import GoogleLogin, { GoogleLoginResponse, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledMiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const StyledRightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledLogo = styled(Image)`
  height: 80px;
`;

const StyledDrip = styled(Image)`
  height: 80px;
  transform: translateY(-10px);
`;

const StyledNavButton = styled(Button)<{ selected: boolean }>`
  width: 100px;
  background: transparent !important;
  font-size: 26px !important;
  color: ${(props) => (props.selected ? '#62aaff' : 'rgba(255, 255, 255, 0.6)')};
  text-decoration: ${(props) => (props.selected ? 'underline' : 'none')};

  &:hover {
    color: #62aaff;
    text-decoration: underline;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isGoogleLoginResponse(obj: any | undefined): obj is GoogleLoginResponse {
  return obj !== null && obj !== undefined && 'googleId' in obj && 'tokenObj' in obj;
}

// Devnet
const clientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ??
  '540992596258-ogaft932ucrhuclnggjup6ipf0g9mkt9.apps.googleusercontent.com';

export const Header: FC = () => {
  const { pathname } = useLocation();
  const { tokenId, setTokenId } = useAuthContext();

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        client_id: clientId
      });
    }
    gapi.load('client:auth2', start);
  });

  return (
    <StyledContainer>
      <StyledLeftContainer>
        <StyledLogo src={Logo} />
        <StyledDrip src={Drip} />
      </StyledLeftContainer>
      <StyledMiddleContainer>
        <Link to="/vault">
          <StyledNavButton selected={pathname === '/vault'}>Vault</StyledNavButton>
        </Link>
        <Link to="/vaults">
          <StyledNavButton selected={pathname === '/vaults'}>Vaults</StyledNavButton>
        </Link>
        <Link to="/positions">
          <StyledNavButton selected={pathname === '/positions'}>Positions</StyledNavButton>
        </Link>
        <Link to="/activewallets">
          <StyledNavButton selected={pathname === '/activewallets'}>Active Wallets</StyledNavButton>
        </Link>
        <Link to="/protoconfig">
          <StyledNavButton selected={pathname === '/protoconfig'}>Proto Config</StyledNavButton>
        </Link>
      </StyledMiddleContainer>
      <StyledRightContainer>
        {!tokenId && (
          <GoogleLogin
            isSignedIn={true}
            style={{
              height: '100%'
            }}
            clientId={clientId}
            buttonText="Login"
            onSuccess={(res) => {
              console.log('login success');
              // TODO(Mocha): Remove tokenId when its no longer needed
              console.log(res);
              console.log(res.code);
              if (isGoogleLoginResponse(res)) {
                setTokenId(res.tokenId);
              }
            }}
            onFailure={(res) => console.log(res)}
            cookiePolicy={'single_host_origin'}
          />
        )}
        {tokenId && (
          <GoogleLogout
            clientId={clientId}
            buttonText="Logout"
            onLogoutSuccess={() => {
              console.log('logout success');
              setTokenId(undefined);
            }}
          />
        )}
        <WalletMultiButton />
      </StyledRightContainer>
    </StyledContainer>
  );
};
