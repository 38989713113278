import { createContext, useContext } from 'react';

export interface AuthContextProps {
  tokenId?: string;
  setTokenId: (argo0: string | undefined) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  setTokenId: () => {
    return;
  }
});

export function useAuthContext(): AuthContextProps {
  const authContext = useContext(AuthContext);
  return authContext;
}
