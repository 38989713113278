import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { mode, Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: (props) => ({
    body: {
      bg: mode('gray.800', '#0A111A')(props)
    }
  })
};

// --theme-900: #0A111A;
// --theme-800: #142233;
// --theme-700: #223B59;
// --theme-600: #315580;
// --theme-500: #406EA6;
// --theme-400: #4577B2;
// --theme-300: #4E88CC;
// --theme-200: #5899E5;
// --theme-100: #62AAFF;

const colors = {
  brand: {
    900: '#0A111A',
    800: '#142233',
    700: '#223B59',
    600: '#315580',
    500: '#406EA6;',
    400: '#4577B2',
    300: '#4E88CC',
    200: '#5899E5',
    100: '#62AAFF'
  }
};

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false
};

export const theme = extendTheme({
  config,
  styles,
  colors,
  components: { Button: { baseStyle: { _focus: { boxShadow: 'none' } } } }
});
